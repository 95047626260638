import NavLink from "./NavLink";
import "./Nav.css";

function Nav() {
  return (
    <nav id="top-menu">
      <NavLink to="/work">work</NavLink>
      <div>/</div>
      <NavLink to="/services">services</NavLink>
      <div>/</div>
      <NavLink to="/about">about</NavLink>
      <div>/</div>
      <NavLink to="/contact">contact</NavLink>
      <div>/</div>
      <NavLink
        className={"gvsfk-link"}
        to="https://www.gvsfk.com/"
        target={"_blank"}
        title={"Gypsy Vampire Slayers from Kansas"}
      >
        gvsfk
      </NavLink>
    </nav>
  );
}

export default Nav;
